/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'leaderboards': {
    width: 19,
    height: 13,
    viewBox: '0 0 19 13',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.334 2a2 2 0 012-2h3.335a2 2 0 012 2v5.112h3.334a2 2 0 012 2v3.446h-2V9.112h-3.335v3.446h-2V2H7.335v10.558h-2V6.445H2v6.112H0V6.445a2 2 0 012-2h3.334V2z" _fill="#898B9B"/>'
  }
})
